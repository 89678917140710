import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { Hero, Section } from "../../components/section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import slugify from "slugify"
import SEO from "../../components/SEO/SEO"
import { enhanceArticle } from "../help/cullai"

const BlogPage = ({ data, location }) => {

  const categories = data.root.categories
  enhanceArticles(categories)

  return (
    <Layout>

      <SEO
        title="Our Blog | DopeAI"
        pathname={location.pathname}
        desc="Blog articles from DopeAI covering product updates and photography topics to help photographers"
      />

      <Hero nogrid className="bg-gray-200 py-20">
        <div className="flex flex-col items-center relative">
          <h1 className="text-4xl text-center">Our Blog</h1>
          <p className="mt-3 text-lg text-gray-500 text-center">Covers product updates and photography topics to help photographers</p>
        </div>
      </Hero>

      <Section noborder gridClassName="gap-16 md:gap-10">
        {categories.map(category => (
          <div key={category.slug} className="md:col-span-6 lg:col-span-4">

            <section className="text-gray-600">
              <header className="flex items-center mb-5 pb-3 text-gray-900 border-b border-gray-200">
                <h2 className="text-xl font-bold">{category.name}</h2>
                <div className="ml-4 text-gray-400">
                  {category.totalCount} articles
                </div>
              </header>
              <ul className="topic-article-feed u-plain-list">
                {category.articles.slice(0, 7).map(article => (
                  <li key={article.slug} className="topic-article">
                    <h3 className="topic-article-title">
                      <Link className="flex items-center text-gray-600 hover:text-blue-600 py-2" to={article.url}>
                        {/* <FontAwesomeIcon className="mr-2 text-xs" icon={faChevronRight}></FontAwesomeIcon> */}
                        {article.frontmatter.title}
                      </Link>
                    </h3>
                  </li>
                ))}
              </ul>
              <footer className="mt-4">
                <Link className="text-sm uppercase inline-flex items-baseline text-blue-500 hover:text-blue-600" to={`/blog/${category.slug}`}>
                  <span>View All</span>
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} size="xs"></FontAwesomeIcon>
                </Link>
              </footer>
            </section>

          </div>
        ))}
      </Section>

    </Layout>
  )

}

export default BlogPage

export const pageQuery = graphql`
query BlogCategoriesQuery {
  root: allMdx(
    filter: { fields: { source: { eq: "blog" } } }
    sort: { fields: frontmatter___date, order: DESC }
  ) {
    categories: group(field: frontmatter___category) {
      name: fieldValue
      totalCount
      articles: nodes {
        excerpt(pruneLength: 200)
        frontmatter {
          title
          category
        }
        fields {
          source
        }
      }
    }
  }
}
`

function slug(str) {
  return slugify(str, { lower: true })
}

function enhanceArticles(categories) {
  categories.forEach(category => {
    category.slug = slug(category.name)
    category.url = `/blog/${category.slug}`
    category.articles.forEach(enhanceArticle)
  })
}